import React, {  useEffect} from 'react';
import { doRedirect, shouldRedirect } from '../common';

interface Props {
    children: React.ReactElement;
}

export const RedirectRapper:React.FC<Props> = ({children}) => {
    const redirect = shouldRedirect();
    useEffect( () => {
        if (redirect) {
          doRedirect();
        }
    }, [shouldRedirect]) 
  
    if (redirect) 
    {   
      return <div/>;
    }
  
    return children;
  }
  
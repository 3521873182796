import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { Data } from './model/Data';

export interface LinkShortenerState {
  readonly selectedLinks: Array<string>;
  readonly allLinks: Array<Data> | null;
}

const initialState: LinkShortenerState = {
    selectedLinks: [],
    allLinks: null
}

export const linkShortenerSlice = createSlice({
  name: 'linkShortener',
  initialState,
  reducers: {
    setAllLinks: (state, action: PayloadAction<Array<Data>>) => {
    state.allLinks = action.payload;
  },
    setSelectedLinks: (state, action: PayloadAction<Array<string>>) => {
      state.selectedLinks = action.payload;
    },
  
  },
})

// Action creators are generated for each case reducer function
export const { setSelectedLinks, setAllLinks } = linkShortenerSlice.actions


type RootPartialState ={
    [linkShortenerSlice.name] : LinkShortenerState
}

const selectSelf = (state:RootPartialState) => state.linkShortener;

export const allLinksSelector = createSelector(selectSelf, linkState => linkState.allLinks)

export const selectedLinksSelector = createSelector(selectSelf, linkState => linkState.selectedLinks)

import React from 'react';
import { Provider } from 'react-redux';
import { initFirebase } from '../../firebase';
import { FirebaseAuthWrapper } from '../FirebaseAuthWrapper/FirebaseAuthWrapper';
import { CoreApp } from '../CoreApp/CoreApp';
import { createStore } from '../../store';
export var AppRoot = function (props) {
    initFirebase(props.appConfig.firebaseConfig); // todo: pass to FirebaseAuthWrapper  instead?
    var store = createStore(props.appConfig.additionalSlices);
    return (React.createElement(Provider, { store: store },
        React.createElement(FirebaseAuthWrapper, null,
            React.createElement(CoreApp, { appConfig: props.appConfig }))));
};

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeAnalytics, getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
var firebaseInstance;
var config;
var firebaseLoaded = false;
export function initFirebase(firebaseConfig) {
    config = firebaseConfig;
    console.info('....init');
    return getFirebase();
}
export function getFirebase() {
    if (firebaseInstance) {
        return firebaseInstance;
    }
    firebaseInstance = initializeApp(config);
    initializeAnalytics(firebaseInstance);
    console.info('....get');
    return firebaseInstance;
}
// todo: split this file into core, auth and analytics
export var logAnalyticEvent = function (eventName) {
    var firebaseInstance = getFirebase();
    var analytics = getAnalytics(firebaseInstance);
    console.info('analytics:', eventName, analytics);
    logEvent(analytics, eventName);
};
export var markAnalyticUserAsAuthenticated = function (auth) {
    var firebaseInstance = getFirebase();
    var analytics = getAnalytics(firebaseInstance);
    // todo: how to actually see this in analytics web?
    setUserProperties(analytics, { 'authenticated': auth });
};
export var getFirebaseAuth = function () { return getAuth(getFirebase()); };

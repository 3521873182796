var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, confirmPasswordReset } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { getFirebase, logAnalyticEvent } from '../../firebase';
import { Modal } from '../Modal/Modal';
import { hideModal } from '../../slices/CoreUISlice';
import { firebaseOoCodeSelector } from '../../store/CreateStore';
import { useInput } from '../../common';
var ChangePasswordConfirmForm = function () {
    var dispatch = useDispatch();
    var nav = useNavigate();
    var firebaseInstance = getFirebase();
    var password = useInput("");
    var _a = useState(undefined), resetPasswordError = _a[0], setResetPasswordError = _a[1];
    var oobCode = useSelector(firebaseOoCodeSelector);
    var changePassword = function (event) {
        event.preventDefault();
        var auth = getAuth(firebaseInstance);
        if (!oobCode) {
            setResetPasswordError("missing verification code");
            return;
        }
        if (firebaseInstance) {
            confirmPasswordReset(auth, oobCode, password.value).then(function () {
                logAnalyticEvent('change password');
                nav('/');
                dispatch(hideModal());
            }).catch(function (error) {
                logAnalyticEvent('change password - error');
                setResetPasswordError(error.code);
            });
        }
    };
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', padding: '10px' } },
        React.createElement(TextField, __assign({ placeholder: "New Password", type: "password", size: "small", variant: "outlined", sx: { marginBottom: '8px' } }, password)),
        resetPasswordError && React.createElement(Alert, { severity: "error", sx: { marginBottom: '8px' } }, resetPasswordError),
        React.createElement(Button, { sx: { width: '100%' }, color: "primary", variant: "contained", onClick: changePassword }, "Continue")));
};
export var ChangePasswordConfirmModal = function () {
    var dispatch = useDispatch();
    var nav = useNavigate();
    var onClose = function () {
        nav('/');
        dispatch(hideModal());
    };
    return (React.createElement(Modal, { title: "Change Password", onClose: onClose, content: React.createElement(ChangePasswordConfirmForm, null) }));
};

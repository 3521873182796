var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { getFirebase, logAnalyticEvent } from '../../firebase';
import { Modal } from '../Modal/Modal';
import { hideModal, showModal } from '../../slices/CoreUISlice';
import { CoreModalID } from "../../model";
import { useInput } from '../../common';
// todo: support google/facebook authentication
var SignupForm = function () {
    var dispatch = useDispatch();
    var firebaseInstance = getFirebase();
    var email = useInput("");
    var password = useInput("");
    var _a = useState(undefined), signupError = _a[0], setSignupError = _a[1];
    var signUp = function (event) {
        event.preventDefault();
        var auth = getAuth(firebaseInstance);
        if (firebaseInstance) {
            createUserWithEmailAndPassword(auth, email.value, password.value).then(function (user) {
                if (user) {
                    logAnalyticEvent('user registration');
                    dispatch(hideModal());
                }
            }).catch(function (error) {
                console.log("signup error:", error);
                setSignupError(error.code);
                logAnalyticEvent('user registration error');
            });
        }
    };
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', padding: '10px' } },
        React.createElement(TextField, __assign({ placeholder: "Email", size: "small", variant: "outlined", sx: { marginBottom: '8px' } }, email)),
        React.createElement(TextField, __assign({ placeholder: "Password", size: "small", variant: "outlined", sx: { marginBottom: '8px' }, type: "password" }, password)),
        signupError && React.createElement(Alert, { severity: "error", sx: { marginBottom: '8px' } }, signupError),
        React.createElement(Button, { sx: { width: '100%' }, color: "primary", variant: "contained", onClick: signUp }, "Create Account")));
};
export var SignupDialog = function () {
    var dispatch = useDispatch();
    var onSignOnClick = function () { return dispatch(showModal(CoreModalID.Login)); };
    return (React.createElement(Modal, { title: "Sign up", content: React.createElement(SignupForm, null), actions: React.createElement("a", { onClick: onSignOnClick, style: { cursor: 'pointer', flex: 1, textAlign: 'center', fontSize: '14px' } }, "Already have an account? Sign in") }));
};

import React from 'react';
import { Home } from './pages/Home';
import { MyLinksPage } from './pages/MyLinks'
import {PageConfig } from 'core-app';
import { About } from './pages/About';

export const allPages:ReadonlyArray<PageConfig> = [
    {
        route: '/about-us',
        component: <About/>,
        title: 'About us',
        showInAppBar: true,
        requiresAuth: false
    },
    {
        route: '/my-links',
        component: <MyLinksPage />,
        title: 'My Links',
        showInAppBar: true,
        requiresAuth: true
    },
    {
        route: '/*',
        component: <Home />,
        title: 'home',
        requiresAuth: false
    },
];

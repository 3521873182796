var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    authenticatedUser: null,
    firebaseOobCode: null
};
var authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        setFirebaseOobCode: function (state, action) {
            state.firebaseOobCode = action.payload;
        },
        clearAuthenticatedUser: function (state) {
            state.authenticatedUser = null;
        },
        setAuthenticatedUser: function (state, action) {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.authenticatedUser = {
                email: action.payload.email,
                displayName: action.payload.displayName
            };
        },
    },
});
// Action creators are generated for each case reducer function
export var setFirebaseOobCode = (_a = authenticationSlice.actions, _a.setFirebaseOobCode), clearAuthenticatedUser = _a.clearAuthenticatedUser, setAuthenticatedUser = _a.setAuthenticatedUser;
export var authenticationSliceReducer = authenticationSlice.reducer;

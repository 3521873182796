var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { authenticationSliceReducer } from '../slices/AuthenticationSlice';
import { coreUIReducer } from '../slices/CoreUISlice';
import { CoreModalID } from '../model';
var makeCombinedReducers = function (additionalSlices) {
    // todo: cleanup
    var temp = {};
    additionalSlices.forEach(function (slice) {
        temp[slice.name] = slice.reducer;
    });
    return combineReducers(__assign({ authentication: authenticationSliceReducer, coreUI: coreUIReducer }, temp));
};
var middleware = function (getDefaultMiddleware) {
    return getDefaultMiddleware({
        serializableCheck: false,
    });
};
export var createStore = function (additionalSlices) {
    return configureStore({
        devTools: true,
        reducer: makeCombinedReducers(additionalSlices),
        middleware: middleware
    });
};
// todo: move  to /selectors
export var isAuthenticatedSelector = function (state) {
    return state.authentication.authenticatedUser !== null;
};
export var activeModalSelector = function (state) { return state.coreUI.visibleModal; };
export var isLoginModalVisibleSelector = function (state) {
    return state.coreUI.visibleModal === CoreModalID.Login;
};
export var isSigninModalVisibleSelector = function (state) {
    return state.coreUI.visibleModal === CoreModalID.SignUp;
};
export var isForgotPasswordModalVisibleSelector = function (state) {
    return state.coreUI.visibleModal === CoreModalID.ForgotPasswordStart;
};
export var isChangePasswordConfirmModalVisibleSelector = function (state) {
    return state.coreUI.visibleModal === CoreModalID.ForgotPasswordConfirm;
};
export var firebaseOoCodeSelector = function (state) {
    return state.authentication.firebaseOobCode;
};
export var isDarkModeOnSelector = function (state) {
    return state.coreUI.darkModeOn;
};

import React, {FC, useState} from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { firebaseConfig} from '../FirebaseConfig';
import { initFirebase, hideModal,SimpleModal } from 'core-app';
import { selectedLinksSelector,setAllLinks, setSelectedLinks} from '../Slice';

export const deleteLinks =  (shortUrlCodes:ReadonlyArray<string>) => {
    const app = initFirebase(firebaseConfig);// getFirebase();
    const functions = getFunctions(app);

    const deleteUrls = httpsCallableFromURL(
        functions,
        // the URL of the function
        "https://deletelinks-xivz7bnm6a-uc.a.run.app"
    );

    return deleteUrls({shortCodes: shortUrlCodes});
}



export const DeleteLinksModal:React.FC = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const links = useSelector(selectedLinksSelector);
    const [isClicked, setIsClicked] = useState(false);

    const onCancelClick = () => dispatch(hideModal());
    const onConfirmClick = () => {
        deleteLinks(links).then( res => {
            setIsClicked(true);
            dispatch(setSelectedLinks([]));
            dispatch(hideModal());
            dispatch(setAllLinks(  (res.data as any).links))
        });
    }; 

    return (<SimpleModal
        mainButttonAction={onConfirmClick}
        secondaryButtonAction={onCancelClick}
        mainButtonText={isClicked?"Deleting...":"Delete"} disableMainButton={isClicked} customContent={  <div>Are you sure you wish to delete?</div>}  secondaryButtonText="Cancel" title={"Confirm Delete"}/>);
    }

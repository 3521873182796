import React, { useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Shortener } from '../components/Shortener';
import {showModal,CoreModalID,getFirebaseAuth } from 'core-app';

export const Home: React.FC = () => {
    const auth = getFirebaseAuth();
    const [user, isLoading, error ] = useAuthState(auth);

    const dispatch = useDispatch();
    const onClick = () => dispatch(showModal(CoreModalID.SignUp));
    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ width: '33%', paddingBottom: '64px' }}>
                <Typography align="center" fontWeight={900} fontSize={28}>Create Short Links</Typography>
                <Typography fontWeight={300} fontSize={16}>zipurl.io is the best link management service to track and share short URLs.</Typography>
            { (!user && !isLoading) && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={onClick} sx={{ fontWeight: 500, marginTop: '8px', width: '60%', minWidth: '180px', fontsize: '20px', padding: '8px' }} variant="contained" color="info">Signup for Free</Button>
                </div> )
            }
            </div>

            <Shortener />
        </div>
    );
}
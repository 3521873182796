import * as React from 'react';
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { styled } from '@mui/material/styles';
import { showModal } from '../../slices/CoreUISlice';
import { useSelector, useDispatch } from 'react-redux';
import { isDarkModeOnSelector } from '../../store/CreateStore';
import { toggleTheme } from '../../slices/CoreUISlice';
import { clearAuthenticatedUser } from '../../slices/AuthenticationSlice';
import { logAnalyticEvent, getFirebaseAuth } from '../../firebase';
import { CoreModalID } from '../../model';
var CreateAccountButton = styled(Button)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.main,
        color: theme.palette.mode === 'light' ? 'white' : 'black',
        "&:hover": {
            backgroundColor: theme.palette.primary.dark
        },
        "&:active": {
            backgroundColor: theme.palette.primary.main
        },
    });
});
var settings = ['Account', 'Settings', 'Logout'];
// todo: pass in additional controls from subapp 
// todo: pass in app name and from subapp
export var CoreAppBar = function (_a) {
    var appIcon = _a.appIcon, pages = _a.pages, appDisplayName = _a.appDisplayName;
    var AppIcon = appIcon;
    var pagesWithLinks = pages.filter(function (p) { return p.showInAppBar; });
    var auth = getFirebaseAuth();
    var _b = useAuthState(auth), user = _b[0], isLoading = _b[1], error = _b[2];
    var dispatch = useDispatch();
    var _c = React.useState(null), anchorElNav = _c[0], setAnchorElNav = _c[1];
    var _d = React.useState(null), anchorElUser = _d[0], setAnchorElUser = _d[1];
    var handleOpenNavMenu = function (event) {
        setAnchorElNav(event.currentTarget);
    };
    var handleCloseNavMenu = function () {
        setAnchorElNav(null);
    };
    var handleOpenUserMenu = function (event) {
        setAnchorElUser(event.currentTarget);
        logAnalyticEvent('user menu opened');
    };
    var handleCloseUserMenu = function () {
        setAnchorElUser(null);
        logAnalyticEvent('user menu closed');
    };
    // const authenticated = useSelector(isAuthenticatedSelector);
    //  const showAvatar = user;// authenticated; // todo: these can be selectors also
    var showSignInAndUp = !user && !isLoading; // !authenticated;
    var showAvatar = !!user;
    var onLoginButtonClick = function () { return dispatch(showModal(CoreModalID.Login)); };
    var onSignUpButtonClick = function () { return dispatch(showModal(CoreModalID.SignUp)); };
    var signInAndUp = (React.createElement(Box, { sx: { flexGrow: 0 } },
        React.createElement(Button, { onClick: onLoginButtonClick, sx: { color: 'white' }, variant: "text" }, "Sign In"),
        React.createElement(CreateAccountButton, { onClick: onSignUpButtonClick, variant: "outlined" }, "CREATE ACCOUNT")));
    var onLogoutClick = function () {
        handleCloseUserMenu();
        var auth = getAuth();
        signOut(auth).then(function (v) {
            dispatch(clearAuthenticatedUser());
        });
    };
    var isDarkModeOn = useSelector(isDarkModeOnSelector);
    var onThemeToggleClick = function () {
        dispatch(toggleTheme());
        logAnalyticEvent('user changed theme to ' + (isDarkModeOn ? 'dark' : 'light'));
        window.localStorage.setItem('darkTheme', String(!isDarkModeOn)); // todo via api
    };
    // todo: needs to respect theme colors
    var logoText = (React.createElement(Link, { style: { color: 'white', letterSpacing: '.3rem', fontFamily: 'monospace', fontWeight: '700', textDecoration: 'none' }, to: '/' }, appDisplayName));
    return (React.createElement(AppBar, { position: "static", style: { flex: 0 } },
        React.createElement(Container, { maxWidth: "xl" },
            React.createElement(Toolbar, { disableGutters: true },
                React.createElement(AppIcon, { sx: { display: { xs: 'none', md: 'flex' }, mr: 1 } }),
                React.createElement(Typography, { variant: "h6", noWrap: true, component: 'span', sx: {
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    } }, logoText),
                React.createElement(Box, { sx: { flexGrow: 1, display: { xs: 'flex', md: 'none' } } },
                    React.createElement(IconButton, { size: "large", "aria-label": "account of current user", "aria-controls": "menu-appbar", "aria-haspopup": "true", onClick: handleOpenNavMenu, color: "inherit" },
                        React.createElement(MenuIcon, null)),
                    React.createElement(Menu, { id: "menu-appbar", anchorEl: anchorElNav, anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        }, keepMounted: true, transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        }, open: Boolean(anchorElNav), onClose: handleCloseNavMenu, sx: {
                            display: { xs: 'block', md: 'none' },
                        } }, pagesWithLinks.map(function (page) { return (React.createElement(MenuItem, { component: Link, to: page.route, key: page.route, onClick: handleCloseNavMenu },
                        React.createElement(Typography, { textAlign: "center" }, page.title))); }))),
                React.createElement(AppIcon, { sx: { display: { xs: 'flex', md: 'none' }, mr: 1 } }),
                React.createElement(Typography, { variant: "h5", noWrap: true, component: "a", href: "", sx: {
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    } }, logoText),
                React.createElement(Box, { sx: { flexGrow: 1, display: { xs: 'none', md: 'flex' } } }, pagesWithLinks.map(function (page) { return (React.createElement(Button, { key: page.route, component: Link, to: page.route, sx: { my: 2, color: 'white', display: 'block' } }, page.title)); })),
                !isLoading && (React.createElement(Box, null,
                    React.createElement(IconButton, { onClick: onThemeToggleClick, sx: { color: 'white', marginRight: '16px' } }, isDarkModeOn ? React.createElement(LightModeOutlinedIcon, null) : React.createElement(DarkModeOutlinedIcon, null)))),
                showSignInAndUp && signInAndUp,
                showAvatar && (React.createElement(Box, { sx: { flexGrow: 0 } },
                    React.createElement(Tooltip, { title: "Open settings" },
                        React.createElement(IconButton, { onClick: handleOpenUserMenu, sx: { p: 0 } },
                            React.createElement(Avatar, { alt: "Remy Sharp", src: "/static/images/avatar/2.jpg" }))),
                    React.createElement(Menu, { sx: { mt: '45px' }, id: "menu-appbar", anchorEl: anchorElUser, anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        }, keepMounted: true, transformOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        }, open: Boolean(anchorElUser), onClose: handleCloseUserMenu }, settings.map(function (setting) { return (React.createElement(MenuItem, { key: setting, disabled: setting !== 'Logout', onClick: setting === 'Logout' ? onLogoutClick : handleCloseUserMenu },
                        React.createElement(Typography, { textAlign: "center" }, setting))); }))))))));
};

import { getFunctions, FunctionsError, httpsCallableFromURL } from "firebase/functions";
import {  NavigateFunction} from 'react-router-dom';
import { getFirebase, initFirebase, logAnalyticEvent } from 'core-app';
import { firebaseConfig} from '../FirebaseConfig';
import { allPages} from '../Pages';
// todo: this could all be made a hook, and reused for future
// url-shortner projects

export const doRedirect = async () => {
    const app = initFirebase(firebaseConfig);// getFirebase();
    const functions = getFunctions(app);
    // todo: httpsCallable('submiturl') if possible
    const getshorturl = httpsCallableFromURL(
        functions,
        // the URL of the function
        "https://getshorturl-xivz7bnm6a-uc.a.run.app"
    );

    const path = document.location.pathname;
    const shortCode = path.slice(1);
  
    getshorturl({ shortCode }).then(res => {
        const { longURL, status } = (res.data as any);
        console.info('....response:', res, longURL, status);
        if (status !== 'success' || !longURL) {
            return;
        }
        logAnalyticEvent('successful short url redirection');
        document.location = longURL;
    }).catch((e: FunctionsError) => {
        console.info('exception:', e.message);
        return;
    })
}

export const shouldRedirect = () => {
    const path = document.location.pathname.slice(1);

    const isRealPage = allPages.find( p => p.route === path);
    return !isRealPage && path !== '/' && path.length === 7;
}

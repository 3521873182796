
export const extractValidURL = (url: string) => {
    const hasProtocol = (!url.startsWith('http://') && !url.startsWith('https://'));
    const temp = (hasProtocol ? '' : 'http://') + url;

    const matches = temp.toLocaleLowerCase().match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (!matches || matches.length !== 1) {
        return undefined;
    }
    return temp;
}

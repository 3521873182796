import React from "react";
import Button from '@mui/material/Button';
import { Modal } from '../Modal/Modal';
// for simple choice modals , 1/2 buttons + text
export var SimpleModal = function (_a) {
    var title = _a.title, disableMainButton = _a.disableMainButton, secondaryButtonAction = _a.secondaryButtonAction, customContent = _a.customContent, mainButtonText = _a.mainButtonText, mainButttonAction = _a.mainButttonAction, secondaryButtonText = _a.secondaryButtonText;
    var content = (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', padding: '10px' } },
        customContent,
        React.createElement("div", { style: { display: 'flex', flexDirection: 'row', marginTop: '16px' } },
            secondaryButtonText && (React.createElement(Button, { sx: { width: '100%', marginRight: '8px' }, variant: "contained", onClick: secondaryButtonAction }, secondaryButtonText)),
            React.createElement(Button, { disabled: disableMainButton, sx: { width: '100%' }, color: "primary", variant: "contained", onClick: mainButttonAction }, mainButtonText))));
    return (React.createElement(Modal, { title: title, content: content }));
};

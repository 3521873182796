var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    visibleModal: null,
    darkModeOn: false
};
var coreUISlice = createSlice({
    name: 'coreUI',
    initialState: initialState,
    reducers: {
        hideModal: function (state) {
            state.visibleModal = null;
        },
        showModal: function (state, action) {
            state.visibleModal = action.payload;
        },
        toggleTheme: function (state) {
            state.darkModeOn = !state.darkModeOn;
        },
        setThemeDark: function (state, action) {
            state.darkModeOn = action.payload;
        }
    },
});
// Action creators are generated for each case reducer function
export var hideModal = (_a = coreUISlice.actions, _a.hideModal), showModal = _a.showModal, toggleTheme = _a.toggleTheme, setThemeDark = _a.setThemeDark;
export var coreUIReducer = coreUISlice.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { getFirebase, logAnalyticEvent } from '../../firebase';
import { Modal } from '../Modal/Modal';
import { useInput } from '../../common';
var ForgotPasswordForm = function () {
    var dispatch = useDispatch();
    var firebaseInstance = getFirebase();
    // todo: auto populate input with email if user is coming from login modal
    var email = useInput("");
    var _a = useState(undefined), resetPasswordError = _a[0], setResetPasswordError = _a[1];
    var _b = useState(false), emailSent = _b[0], setEmailSent = _b[1];
    var resetPassword = function (event) {
        event.preventDefault();
        var auth = getAuth(firebaseInstance);
        if (firebaseInstance) {
            sendPasswordResetEmail(auth, email.value).then(function () {
                setEmailSent(true);
                logAnalyticEvent('requested password reset email');
            }).catch(function (error) {
                setResetPasswordError(error.code);
                logAnalyticEvent('requested password reset email');
            });
        }
    };
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', padding: '10px' } },
        React.createElement(TextField, __assign({ placeholder: "Email", size: "small", variant: "outlined", sx: { marginBottom: '8px' } }, email)),
        resetPasswordError && React.createElement(Alert, { severity: "error", sx: { marginBottom: '8px' } }, resetPasswordError),
        emailSent && React.createElement(Alert, { severity: "success", sx: { marginBottom: '8px' } }, "Password reset email sent."),
        !emailSent && (React.createElement(Button, { sx: { width: '100%' }, color: "primary", variant: "contained", onClick: resetPassword }, "Send Email With Reset Link"))));
};
export var ForgotPasswordDialog = function () {
    return (React.createElement(Modal, { title: "Forgot password", content: React.createElement(ForgotPasswordForm, null) }));
};

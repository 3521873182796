var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useState } from "react";
import { useDispatch } from 'react-redux';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirebase, logAnalyticEvent } from '../../firebase';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import { Modal } from '../Modal/Modal';
import { hideModal, showModal } from '../../slices/CoreUISlice';
import { CoreModalID } from "../../model";
import { useInput } from '../../common';
var LoginForm = function () {
    var dispatch = useDispatch();
    var firebaseInstance = getFirebase();
    var email = useInput("");
    var password = useInput("");
    var _a = useState(undefined), loginError = _a[0], setLoginError = _a[1];
    var auth = getAuth(firebaseInstance);
    var handleForgotPasswordClick = function () {
        dispatch(showModal(CoreModalID.ForgotPasswordStart));
    };
    var signIn = useCallback(function (event) {
        event.preventDefault();
        if (firebaseInstance) {
            signInWithEmailAndPassword(auth, email.value, password.value).then(function (user) {
                if (user) {
                    logAnalyticEvent('login');
                    dispatch(hideModal());
                }
            }).catch(function (error) {
                console.info(error);
                setLoginError(error.code);
                logAnalyticEvent('login error');
            });
        }
    }, [auth, email.value, password.value]);
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', padding: '10px' } },
        React.createElement(TextField, __assign({ placeholder: "Email", size: "small", variant: "outlined", sx: { marginBottom: '8px' } }, email)),
        React.createElement(TextField, __assign({ placeholder: "Password", type: "password", size: "small", variant: "outlined", sx: { marginBottom: '8px' } }, password)),
        loginError && React.createElement(Alert, { severity: "error", sx: { marginBottom: '8px' } }, loginError),
        React.createElement(Divider, null),
        React.createElement("a", { onClick: handleForgotPasswordClick, style: { cursor: 'pointer', fontSize: '14px', margin: '0 0 8px' } }, "Forgot password?"),
        React.createElement(Button, { sx: { width: '100%' }, color: "primary", variant: "contained", onClick: signIn }, "Continue")));
};
export var LoginDialog = function () {
    var dispatch = useDispatch();
    var onSignUpClick = function () { return dispatch(showModal(CoreModalID.SignUp)); };
    return (React.createElement(Modal, { title: "Sign In", content: React.createElement(LoginForm, null), actions: React.createElement("a", { onClick: onSignUpClick, style: { cursor: 'pointer', flex: 1, textAlign: 'center', fontSize: '14px' } }, "Don't have an account? Sign up") }));
};

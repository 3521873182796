import React from 'react';
import ReactDOM from 'react-dom/client';
import { MyApp } from './MyApp';
import { RedirectRapper} from './components/RedirectWrapper';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
      <RedirectRapper>
        <MyApp />
      </RedirectRapper>
);
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { hideModal } from '../../slices/CoreUISlice';
import { useDispatch } from 'react-redux';
var BootstrapDialog = styled(Dialog)(function (_a) {
    var theme = _a.theme;
    return ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    });
});
var BootstrapDialogTitle = function (props) {
    var children = props.children, onClose = props.onClose, other = __rest(props, ["children", "onClose"]);
    return (React.createElement(DialogTitle, __assign({ sx: { m: 0, p: 2, textAlign: 'center' } }, other),
        children,
        onClose ? (React.createElement(IconButton, { "aria-label": "close", onClick: onClose, sx: {
                position: 'absolute',
                right: 8,
                top: 8,
                color: function (theme) { return theme.palette.grey[500]; },
            } },
            React.createElement(CloseIcon, null))) : null));
};
export var Modal = function (_a) {
    var content = _a.content, actions = _a.actions, title = _a.title, onClose = _a.onClose;
    /*    const [open, setOpen] = React.useState(false);
      
        const handleClickOpen = () => {
          setOpen(true);
        };
        const handleClose = () => {
          setOpen(false);
        };*/
    var dispatch = useDispatch();
    var handleClose = onClose ? onClose : function () {
        dispatch(hideModal());
    };
    return (React.createElement(BootstrapDialog, { onClose: handleClose, "aria-labelledby": "customized-dialog-title", open: true },
        React.createElement(BootstrapDialogTitle, { id: "customized-dialog-title", onClose: handleClose }, title),
        React.createElement(DialogContent, { dividers: true }, content),
        actions && (React.createElement(DialogActions, null, actions))));
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { BrowserRouter, Routes, Route, useSearchParams, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { LoginDialog } from '../LoginForm/LoginForm';
import { SignupDialog } from '../SignupForm/SignupForm';
import { ForgotPasswordDialog } from '../ForgotPasswordDialog/ForgotPasswordDialog';
import { ChangePasswordConfirmModal } from '../ChangePasswordConfirmModal/ChangePasswordConfirmModal';
import { CoreAppBar } from '../CoreAppBar/CoreAppBar';
import { isDarkModeOnSelector } from '../../store/CreateStore';
import { setThemeDark } from '../../slices/CoreUISlice';
import { showModal } from '../../slices/CoreUISlice';
import { setFirebaseOobCode } from '../../slices/AuthenticationSlice';
import { CoreModalID } from '../../model';
import { getFirebaseAuth } from '../../firebase';
import { activeModalSelector } from '../../store';
// todo: move these themes
var darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
var lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});
// todo:
// - sep file
var PasswordResetPage = function () {
    var nav = useNavigate();
    var dispatch = useDispatch();
    var searchParams = useSearchParams()[0];
    var oobCode = searchParams.get('oobCode');
    useEffect(function () {
        if (typeof oobCode !== 'string') {
            nav('/');
            return;
        }
        // todo: what to do if oob code is missing (user loaded page directly)... redirect home?
        dispatch(setFirebaseOobCode(oobCode));
        dispatch(showModal(CoreModalID.ForgotPasswordConfirm));
    }, []);
    return React.createElement("div", null);
};
// todo: ownfile
var PageWithAuthentication = function (_a) {
    var innerPage = _a.innerPage, requiresAuth = _a.requiresAuth;
    var dispatch = useDispatch();
    var auth = getFirebaseAuth();
    var _b = useAuthState(auth), user = _b[0], isLoading = _b[1], error = _b[2];
    if (!requiresAuth) {
        return innerPage;
    }
    if (isLoading) {
        return React.createElement("div", { style: { display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' } },
            React.createElement(CircularProgress, { color: "inherit" }));
    }
    var onLoginButtonClick = function () { return dispatch(showModal(CoreModalID.Login)); };
    var onSignupButtonClick = function () { return dispatch(showModal(CoreModalID.SignUp)); };
    if (!user) {
        // todo: cleanup/links
        return React.createElement("div", { style: { flexDirection: 'column', display: 'flex', flex: '1', fontSize: '20px', alignItems: 'center', justifyContent: 'center' } },
            React.createElement("div", null, "This page requires you to be logged in."),
            React.createElement("div", null,
                "Please ",
                React.createElement("a", { style: { textDecoration: 'underline', cursor: 'pointer' }, onClick: onSignupButtonClick }, "CREATE ACCOUNT"),
                " or ",
                React.createElement("a", { style: { textDecoration: 'underline', cursor: 'pointer' }, onClick: onLoginButtonClick }, "SIGN IN"),
                "."));
    }
    return React.createElement(React.Fragment, null, innerPage);
};
var modalIDtoModal = function (id, appConfig) {
    if (!id)
        return undefined;
    if (id === CoreModalID.ForgotPasswordConfirm)
        return React.createElement(ChangePasswordConfirmModal, null);
    if (id === CoreModalID.ForgotPasswordStart)
        return React.createElement(ForgotPasswordDialog, null);
    if (id === CoreModalID.SignUp)
        return React.createElement(SignupDialog, null);
    if (id === CoreModalID.Login)
        return React.createElement(LoginDialog, null);
    return appConfig.modals[id];
};
export var CoreApp = function (_a) {
    var appConfig = _a.appConfig;
    var pages = appConfig.pages, appIcon = appConfig.appIcon, appDisplayName = appConfig.appDisplayName, modals = appConfig.modals;
    var dispatch = useDispatch();
    var visibleModalID = useSelector(activeModalSelector);
    var modal = modalIDtoModal(visibleModalID, appConfig);
    var isDarkTheme = window.localStorage.getItem('darkTheme') === 'true'; // via api
    if (useSelector(isDarkModeOnSelector) !== isDarkTheme) {
        dispatch(setThemeDark(isDarkTheme));
    }
    var locationPath = window.location.pathname;
    useEffect(function () {
        var page = pages.find(function (p) { return p.route === locationPath; });
        document.title = appDisplayName + ' - ' + (page ? page.title : locationPath);
    }, [locationPath]);
    return (React.createElement(ThemeProvider, { theme: isDarkTheme ? darkTheme : lightTheme },
        React.createElement(Paper, { sx: { display: 'flex', flexDirection: 'column', height: '100vh' } },
            React.createElement(BrowserRouter, null,
                React.createElement(CoreAppBar, { appIcon: appIcon, pages: pages, appDisplayName: appDisplayName }),
                modal,
                React.createElement(Routes, null,
                    React.createElement(Route, { key: "password-reset", path: "password-reset", element: React.createElement(PasswordResetPage, null) }),
                    pages.map(function (page) {
                        var wrappedPage = React.createElement(PageWithAuthentication, { innerPage: page.component, requiresAuth: page.requiresAuth });
                        return React.createElement(Route, { key: page.route, path: page.route, index: page.index, element: wrappedPage });
                    }))))));
};

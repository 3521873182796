export const firebaseConfig = {
    apiKey: "AIzaSyA3WjOLFRvcPwwnTZll9T-_Pbkfst-nhXs",
    authDomain: "url-shortener-d2812.firebaseapp.com",
    databaseURL: "https://url-shortener-d2812-default-rtdb.firebaseio.com",
    projectId: "url-shortener-d2812",
    storageBucket: "url-shortener-d2812.appspot.com",
    messagingSenderId: "718025014550",
    appId: "1:718025014550:web:f0d072c71a0f3eadcb2437",
    measurementId: "G-KEBCS18KFS"
};


import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth } from "firebase/auth";
import { getFirebase, markAnalyticUserAsAuthenticated } from '../../firebase';
import { setAuthenticatedUser, clearAuthenticatedUser } from '../../slices/AuthenticationSlice';
export var FirebaseAuthWrapper = function (props) {
    var dispatch = useDispatch();
    var onAuthChange = function (user) {
        if (user) {
            markAnalyticUserAsAuthenticated(true);
            dispatch(setAuthenticatedUser(user));
        }
        else {
            markAnalyticUserAsAuthenticated(false);
            dispatch(clearAuthenticatedUser());
        }
    };
    useEffect(function () {
        var firebaseInstance = getFirebase();
        var firebaseAuth = getAuth(firebaseInstance);
        firebaseAuth.onAuthStateChanged(onAuthChange);
    }, [onAuthChange]);
    return props.children;
};

import React, { useCallback, useState } from 'react';
//import { getFunctions, httpsCallable,FunctionsError  } from 'firebase/compat/functions';
import { getFunctions, httpsCallable, FunctionsError, httpsCallableFromURL } from "firebase/functions";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import SendIcon from '@mui/icons-material/Send';
import CopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { useInput, getFirebase, logAnalyticEvent } from 'core-app';
import { extractValidURL } from '../common';

export const Shortener: React.FC = () => {
    const input = useInput('');
    const [shortURL, setShortURL] = useState('');
    const [errorAlertMessage, setErrorAlertMessage] = useState('');

    const app = getFirebase();
    const functions = getFunctions(app);
    // todo: httpsCallable('submiturl') if possible
    const submiturl = httpsCallableFromURL(
        functions,
        // the URL of the function
        "https://submiturl-xivz7bnm6a-uc.a.run.app"
    );


    // todo: usecallback
    const handleShortenClick = useCallback( () => {
        setShortURL('');
        setErrorAlertMessage('');
        const validURL = extractValidURL(input.value);
        if (!validURL) {
            setErrorAlertMessage('Invalid url format');
            return;
        }

        logAnalyticEvent('shortened url');
        input.onChange({target:{value:''}})

        submiturl({ longURL: validURL })
            .then((result) => {
                const data = result.data as any; 
                if (data.status === 'error') {
                    setErrorAlertMessage(data.message)
                    return;
                }
          
                setShortURL( data.shortURL);
              
            }).catch( (e:FunctionsError)=> {
         
                setErrorAlertMessage(e.message)
            });
    }, [input.value,submiturl] );

    const submitButton = (
        <Button endIcon={<SendIcon />} onClick={handleShortenClick} variant="contained" style={{ padding: '8px 28px 8px 28px', height: '100%' }}>
            Shorten
        </Button>
    );


    const handleCopyClick = useCallback( () => {
        logAnalyticEvent('copied url to clipboard');
        navigator.clipboard.writeText(shortURL)
    }, [shortURL] );


    return (
        <div style={{ height: '56px', width: '33%', minWidth:'375px' }}>
            <TextField placeholder='Your URL Here' style={{ width: '100%', paddingRight: '8px' }}
                InputProps={{ endAdornment: submitButton }} {...input}
            />
            <div style={{ marginTop: '8px' }}>
                {shortURL && <Alert variant='filled' severity="success"  >
                    <div style={{ width: '100%' }}>
                    Shortened URL: {shortURL}
                        <IconButton onClick={handleCopyClick} size="small" style={{ padding: '0 0 0 8px' }} >
                            <CopyIcon fontSize='small' />
                        </IconButton>
                    </div>
                </Alert>}
                {errorAlertMessage && <Alert variant='filled' severity="error" >{errorAlertMessage}

                </Alert>}
            </div>

        </div>
    );
}
import BoltIcon from '@mui/icons-material/Bolt'; 
import { firebaseConfig } from './FirebaseConfig';
import { allPages } from './Pages';
import { AppConfig} from 'core-app';
import { DeleteLinksModal} from './components/DeleteLinksModal';
import { linkShortenerSlice} from './Slice';

export const appConfig:AppConfig = {
  firebaseConfig,
  pages: allPages,
  appDisplayName: 'ZIPURL.IO',
  appIcon: BoltIcon,
  modals: {
    'ConfirmDeleteLinks':  <DeleteLinksModal/>
  },
  additionalSlices: [linkShortenerSlice]
  
}

